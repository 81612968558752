<template>
  <transition-group tag="ul" name="list" class="doc-ul">
    <li v-for="item in docData.data" :key="item.id">
      <router-link :to="{ name: 'DocDetail', query: { docId: item.id } }">
        <div class="doc-title">
          <img class="doc-type-icon" :src="item.libIcon" alt="" />
          <span style="color: #DF0024;" v-if="item.isVerify==0">【待审核】 </span>
          {{ item.docTitle }}
        </div>
        <div class="doc-description">
          {{ subStrDoc(item.docIntroduction) }}
        </div>
        <div class="doc-info">
          <span class="doc-date">
            <!-- <img src="http://www.bncc.org.cn/static/m/images/icon91.png" alt="" /> -->
            <img src="@/assets/img/common/icon91.png" alt="" />
            {{ dateFormat(item.createTime) }}
          </span>
          <span class="doc-view">
            <!-- <img src="http://www.bncc.org.cn/static/m/images/icon92.png" alt="" /> -->
            <img src="@/assets/img/common/icon27.png" alt="" />
            {{ item.clickCount }}
          </span>
        </div>
      </router-link>
    </li>
  </transition-group>
</template>



<script lang="ts">
import { defineComponent } from "vue";
import dateFormat from "../../utils/dateFormat";
import axios from "axios";
export default defineComponent({
  name: "ClassItem",
  props: {
    docData: Object,
  },
  setup() {
    function viewCourse() {
      console.log(111);
    }
    function subStrDoc(str: string) {
      if (str.length > 200) {
        return str.substr(0, 200);
      }
      return str;
    }
    return {
      viewCourse,
      dateFormat,
      subStrDoc,
    };
  },
});
</script>


<style lang="scss" scoped>
/*文库 start*/
.doc-ul {
  width: 355px;
  margin: 0 auto;
}
.doc-ul li {
  display: block;
  width: 100%;

  box-sizing: border-box;

  padding: 20px 10px 15px 10px;
  border-bottom: 1px solid #eee;
}

.doc-title {
  color: #444444;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.doc-title img {
  width: 17px;
  height: 17px;
  transform: translateY(-2px);
  vertical-align: middle;
}

.doc-description {
  font-size: 16px;
  line-height: 24px;
  color: #999999;

  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.doc-description p {
  margin: 0;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.doc-info {
  margin-top: 10px;
  color: #999999;
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
}
.doc-info .doc-date {
  margin-right: 25px;
  display: inline-flex;
  align-items: center;
}
.doc-info .doc-date img {
  /* vertical-align: middle; */
  width: 13.5px;
  height: 13.5px;
  margin-right: 5px;
}
.doc-info .doc-view {
  display: inline-flex;
  align-items: center;
}
.doc-info .doc-view img {
  /* vertical-align: middle; */
  width: 16px;
  height: 10px;
  margin-right: 5px;
}

/*文库 end*/
</style>
