
type dateTime = string | undefined

export default function dateFormat(date: dateTime, format = 'yyyy/MM/dd'): dateTime {
    if (typeof date === 'string') {
        if (format == 'HH:mm') {
            return date.substr(12, 4)
        }
        return date.substr(0, 10)
    } else {
        return date
    }
}


export function calendarFormat(date: dateTime) {
    if (typeof date === 'string') {
        const dateArr = [];
        dateArr.push(date.substr(0, 4))
        dateArr.push(date.substr(5, 2))
        dateArr.push(date.substr(8, 2))
        return dateArr

    } else {
        return []
    }
}

function changeDate(date: dateTime) {
    // const hour = Number(date) + 8;
    const hour = Number(date)
    if(hour < 10){
        return '0' + hour;
    }else{
        return hour
    }
}

export function compareFormat(startDate: dateTime, endDate: dateTime) {
    
    if (typeof startDate === 'string' && typeof endDate === 'string') {
        const year = startDate.substr(0, 10);
        const hourStart = startDate.substr(11, 2);
        const secondStart = startDate.substr(13, 3);
        const hourEnd = endDate.substr(11, 2);
        const secondEnd = endDate.substr(13, 3);
        return year + " " + changeDate(hourStart) + secondStart + "-" + changeDate(hourEnd) + secondEnd
    } else {
        return startDate + ' ' + endDate
    }
}

function addZero(num: number) {
    return num < 10 ? '0' + num : num;
}

//处理后台返回时间 转换问题
export function timeFormat(timedata: dateTime) {
    if (typeof timedata === 'string'){
        const date = new Date(timedata.substr(0, 19));
        const Year = date.getFullYear();
        const Month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        const d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        const Hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        const Minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        const Seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        const overTime = Year + "/" + Month + "/" + d + " " + Hours + ":" + Minutes + ":" + Seconds
    //***至此以上是将时间2020-03-18T01:57:23.000+0000转为正常时间格式，以下为将时间进行增加8小时解决时区差异的操作***
        const time = new Date(Date.parse(overTime));
        time.setTime(time.setHours(time.getHours() + 8));
        const Y = time.getFullYear() + '-';
        const M =addZero(time.getMonth() + 1) + '-';
        const D =addZero(time.getDate()) + ' ';
        const h = addZero(time.getHours()) + ':';
        const m =addZero(time.getMinutes()) + ':';
        const s =addZero(time.getSeconds());
        return (Y + M + D + "" + h + m + s)
    }else{
        return '0'
    }
}



// // 比较时间大小
// export function contrast(startDate: string, endDate: string) {
//     return new Date(timeFormat(endDate)).getTime() - new Date(timeFormat(startDate)).getTime() > 0 ? true : false;
// }