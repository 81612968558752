
    import {
        defineComponent,
        onMounted,
        ref,
        nextTick,
        inject,
        watch,
        reactive,
        onActivated,
    } from "vue";
    import banner from "@/components/common/Swiper.vue";
    import list from "@/components/common/list.vue";
    import DocItem from "@/components/common/DocItem.vue";

    import api from "@/api/axios";
    import { useRoute , onBeforeRouteLeave } from "vue-router";

    export default defineComponent({
        name: "Library",
        components: {
            banner,
            list,
            DocItem,
        },
        setup() {
            const list = ref(null);
            const hotSaleList = ref(null);
            api.get("/M/Discover").then((res): void => {
                list.value = res.data.obj.listimg;
            });
            //  切换列表菜单
            //  内部人员，显示售后模块
            const userInfo = inject("userInfo") as any;

            const route = useRoute();
            const index = ref(0);
            onActivated(() => {
                // initData();
            });

            const unMountComponent = ref(false);
            onBeforeRouteLeave((to, from) => {
                if (to.name != "TopicDetail" && to.name != "DocDetail" && to.name != "Personal") {
                    unMountComponent.value = true;
                    nextTick(() => {
                        unMountComponent.value = false;
                    });
                }
                return true;
            });
            return {
                list,
                unMountComponent
            };
        },
    });
