<template>
    <div v-if="!unMountComponent">
        <banner :list="list"></banner>
        <list url="/M/Server/GetLastLib" v-show="true">
            <template v-slot:default="data">
                <doc-item :docData="data"></doc-item>
            </template>
        </list>
        <router-link class="edit" :to="{ name: 'PostTopic' }">
            <img src="https://beinabncc.oss-cn-beijing.aliyuncs.com/file//icon3420210419.png" alt="" />
        </router-link>
    </div>
</template>

<script lang="ts">
    import {
        defineComponent,
        onMounted,
        ref,
        nextTick,
        inject,
        watch,
        reactive,
        onActivated,
    } from "vue";
    import banner from "@/components/common/Swiper.vue";
    import list from "@/components/common/list.vue";
    import DocItem from "@/components/common/DocItem.vue";

    import api from "@/api/axios";
    import { useRoute , onBeforeRouteLeave } from "vue-router";

    export default defineComponent({
        name: "Library",
        components: {
            banner,
            list,
            DocItem,
        },
        setup() {
            const list = ref(null);
            const hotSaleList = ref(null);
            api.get("/M/Discover").then((res): void => {
                list.value = res.data.obj.listimg;
            });
            //  切换列表菜单
            //  内部人员，显示售后模块
            const userInfo = inject("userInfo") as any;

            const route = useRoute();
            const index = ref(0);
            onActivated(() => {
                // initData();
            });

            const unMountComponent = ref(false);
            onBeforeRouteLeave((to, from) => {
                if (to.name != "TopicDetail" && to.name != "DocDetail" && to.name != "Personal") {
                    unMountComponent.value = true;
                    nextTick(() => {
                        unMountComponent.value = false;
                    });
                }
                return true;
            });
            return {
                list,
                unMountComponent
            };
        },
    });
</script>
<style lang="scss" scoped>
    .edit {
        position: fixed;
        width: 40px;
        height: 40px;
        right: 10px;
        top: 80%;
        margin-top: -20px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        z-index: 100;
    }
    .edit img {
        width: 100%;
        height: 100%;
        display: block;
    }
</style>
